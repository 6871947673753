<template>
    <div class="header">
        <div class="header-top">
            <h1>Minhas lojas</h1>
            <Tooltip 
                class="tooltip"
                :tooltip_message="$t('my_lists.tooltip_message')" 
                size="0.8rem"
            />
        </div>
        <div class="header-center">
            <p>
                Os produtos abaixo serão exibidos na sua lojinha. Você pode adicionar novos produtos clicando em
                <strong>Adicionar favoritos</strong>, ou caso deseje remover, clique no coração, localizado acima do item.
            </p>
        </div>
    </div>
</template>
<script setup>
// Components
import Tooltip from '@/modules/main/components/tooltip.vue';
//Components
</script>

<style scoped>
.header-top {
    display: flex;
    align-items: center;
}

h1 {
    font-size: clamp(16px, 4vw, 25px);
    line-height: 0;
    text-transform: initial;
    font-weight: 400;
}

.tooltip {
    position: relative;
    top: 0.1rem;
}

.header-center {
    margin-top: 5px;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.header-center p {
    font-size: clamp(12px, 4vw, 15px);
    margin: 13px 0px;
}

.header-bottom {
    margin-top: 28px;
}

@media ( min-width: 599.99px ) {
    .header-center p {
        max-width: 800px;
    }
}
</style>