<template>
    <q-dialog persistent class="relative" ref="dialogRef" @hide="dialogHide">
        <q-card 
            ref="refScrollableCard" 
            class="q-dialog-plugin relative" 
        >
            <Header class="header-component" :dialogClose="onDialogCancel" />
            <q-layout>
                <section class="q-px-md q-pb-md">
                    <div class="container">
                        
                        <Suspense>
                            <template #default>
                                <Sidebar 
                                    class="col-md-4 sidebar"
                                    v-if="$q.screen.gt.sm" 
                                    :price_range="getFilters?.price_range" 
                                />
                                <q-drawer
                                    v-else
                                    v-model="drawer"
                                    show-if-above
                                    no-swipe-open
                                    no-swipe-close
                                    :width="300"
                                    @hide="drawerHide"
                                >   
                                    <div class="drawer-header q-px-md">
                                        <h2>Filtros</h2>
                                        <button class="btn-close-drawer" @click="drawerHide">
                                            <q-icon name="close" size="1.2rem" />
                                        </button>
                                    </div>
                                    <div class="q-px-md q-pb-md q-pt-none">
                                        <Sidebar :price_range="getFilters?.price_range" />
                                    </div>
                                </q-drawer>
                            </template>
                            <template #fallback v-if="$q.screen.gt.sm">
                                <SidebarSkeleton class="col-md-4"/>
                            </template>
                        </Suspense>
           
                       
                        <Suspense>
                            <template #default>
                                <Products 
                                    class="col-md-8" 
                                    :scrollToTop="scrollToTop"
                                    :activeScrollBoxShadowHeader="activeScrollBoxShadowHeader"
                                />
                            </template>
                            <template #fallback>
                                <ProductsSkeleton class="col-md-8" />
                            </template>
                        </Suspense>
                    </div>
                </section>
            </q-layout>
        </q-card>
    </q-dialog>
</template>
<style scoped lang="scss">
    .mobile
    {
        .q-dialog__inner--minimized > div{
            height: 100vh !important;
            max-height: 100vh !important;
            width: 100vw;
            -webkit-overflow-scrolling: touch   
        }
        
        .q-dialog__inner > div {
            overflow: hidden;
        }
    }

    .desktop {
        .q-dialog__inner--minimized > div{
            width: 90vw !important;
            max-width: 1100px;
            height: var(--my-lists_q-card-height);
            max-height: var(--my-lists_q-card-height);
        }
    }

    .platform-ios .section {
        padding-bottom: 50px;
        -webkit-overflow-scrolling: touch !important; /* Ativa a rolagem com inércia no Safari */
    }

    .btn-close-drawer {
        margin-left: auto;
        display: flex;
        outline: none;
        background: none;
        border: none;
        padding-right: 0px;
    }

    .btn-close-drawer .q-icon {
        color: var(--primary-custom-color);
    }

    :deep(.theme-light-dark .q-drawer) {
        background: #fff;
    }

    :deep(.q-dialog__inner--minimized) {
        padding: 0px
    }
    
    .header-component {
        position: sticky;
        top: 0;
        background: white;
        z-index: 10;
    }

    .header-shadow {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    }

    .container .col-md-4 {
        flex-basis: 30%;
        flex-shrink: 0;
    }
    
    .container .col-md-8 {
        flex-basis: 68%;
        flex-grow: 1;
    }

    .drawer-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px
    }

    .drawer-header h2 {
        font-size: clamp(16px, 4vw, 17px);
        font-weight: 400
    }

    :deep(.q-drawer__backdrop) {
        overflow: hidden;
    }

    :deep(.q-drawer) {
        background: #fff !important;
    }

    @media ( min-width: 640px ) {
        .q-dialog__inner--minimized .q-card {
            overflow: hidden;
        }

        :deep(.q-dialog__inner--minimized) {
            padding: 24px
        }
        
        .container {
            gap: 30px;
            margin-top: 25px;
            display: flex;
            height: calc(var(--my-lists_q-card-height) - 100px);
        }

        :deep(.q-dialog__inner) {
            padding: 0px
        }
    }
</style>
<script setup>
import { useDialogPluginComponent } from 'quasar'
import { defineEmits, watch, ref, onMounted } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { useQuasar } from 'quasar';

import Sidebar from './sidebar.vue';
import Products from './products.vue';
import SidebarSkeleton from './skeletons/sidebar.vue';
import ProductsSkeleton from './skeletons/products.vue';
import Header from './header.vue'

defineEmits([
    ...useDialogPluginComponent.emits
])

const $q = useQuasar();

const drawer = ref(false);
const refScrollableCard = ref(null);

const { dialogRef, onDialogCancel } = useDialogPluginComponent()

const { getFilters, getLoading, getDrawer } = useGetters({
    getFilters: 'my_lists/getFilters',
    getLoading: 'my_lists/getLoading',
    getDrawer: 'my_lists/getDrawer'
})

const { resetFiltersPayloads, setDrawer } = useActions({
    resetFiltersPayloads: 'my_lists/resetFiltersPayloads',
    setDrawer: 'my_lists/setDrawer'
})

watch(getDrawer, (newValue) => {
    drawer.value = newValue
})

watch(getLoading, (newValue) => {
    const elements = document.querySelectorAll('section, .header-component .header-content');

    if ( newValue ) elements.forEach(el => el.classList.add('flipnet-opacity'))
    else elements.forEach(el => el.classList.remove('flipnet-opacity'))
})


// Insere/Remove o box-shadow ao header conforme o scroll, ex: Rolar para baixo add o box-shadow
const activeScrollBoxShadowHeader = (event) => {
    const headerComponent = document.querySelector('.header-component');
    const scrollTop = event.target.scrollTop; // Acessa o elemento que disparou o evento

    if ( scrollTop >= 20 ) headerComponent.classList.add('header-shadow')
    else headerComponent.classList.remove('header-shadow')
}


// Função disparada quando fecha o dialog, reseta todos os filtros
const dialogHide = () => {
    resetFiltersPayloads()
};


const drawerHide = () => setDrawer(!getDrawer)


// Ao trocar de página, o scroll volta para o topo
const scrollToTop = () => {
    setTimeout(() => {
        document.querySelector('.section-products').scrollTo({
            behavior: 'smooth', 
            top: 0 
        })
    }, 50)
}




// Define a altura total  do dialog/card com base na altura da viewport
const setSizePopup = () => {
    const height = ( window.innerHeight < 650 ? window.innerHeight : 650 ) - 30;

    document.documentElement.style.setProperty('--my-lists_q-card-height', `${height}px`)
}

onMounted(() => setSizePopup())

</script>