<template>
    <div class="row">
        <q-btn color="primary" :size="sizeButtons()" @click="addFavorites">
            <q-icon name="favorite" size="1rem" />
            <span class="q-ml-xs">Adicionar favoritos</span>
        </q-btn>
        <q-btn color="primary" :size="sizeButtons()" @click="externalLink">
            <q-icon name="open_in_new" size="1rem" />
            <span class="q-ml-xs">Ver minha página</span>
        </q-btn>
    </div>
</template>
<script setup>
import { useActions, useGetters } from 'vuex-composition-helpers';
import selectProductsComponent from './components/popup-products/selectProductsComponent.vue';
import { useQuasar } from 'quasar';

const $q = useQuasar()

const { getPartnerLandingLink } = useGetters({
    getPartnerLandingLink: "my_lists/getPartnerLandingLink",
})

const { fetchPartnerLandingLink } = useActions({
    fetchPartnerLandingLink: "my_lists/fetchPartnerLandingLink",
})


// METHODS

// Define o tamanho dos botões de acordo com a viewport
const sizeButtons = () => $q.screen.lt.md ? 'sm' : 'md';


// Abre o Dialog para selecionar os produtos Favoritos
const addFavorites = () => {
    $q.dialog({
        component: selectProductsComponent,
    })
}

// Leva para a página de favoritos na loja do parceiro
const externalLink = () =>  window.open(getPartnerLandingLink.value, '_blank');


try {
    const output = await fetchPartnerLandingLink()

    console.log('fetchPartnerLandingLink', output)
} catch (error) {
    alert('error')
    console.error('Houve um erro', error)
}
</script>
<style scoped lang="scss">
.row {
    justify-content: end;
    gap: 15px;
    border-radius: 40px;
}

.q-btn:before {
    box-shadow: none !important;
}

.q-btn:first-child {
    background: var(--primary-custom-color) !important;
}

.q-btn:last-child {
    background: none !important;
    border: 1px solid var(--primary-custom-color);
    transition: background-color 250ms ease;

    span,
    i {
        color: var(--primary-custom-color);
    }
}

.q-btn:last-child:hover {
    background-color: var(--primary-custom-color) !important;

    span,
    i {
        color: #fff;
    }
}

:deep(.q-btn__content span) {
    text-transform: initial
}
</style>