<template>
    <div class="row">
        <q-skeleton />
        <q-skeleton />
    </div>
</template>
<style scoped>
.q-skeleton {
    width: calc(100% / 2.15);
}

.row {
    gap: 16px;
    margin-left: auto;
    flex-wrap: nowrap !important;
    justify-content: end;
}

@media ( min-width: 640px ) {
    .row {
        width: fit-content;
    }

    .q-skeleton {
        width: 170px;
        height: 32px
    }
}
</style>