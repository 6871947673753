<template>
    <div class="filter" v-if="Object.keys(getFilters).length">
        <div class="filters">
            <div v-for="({ data, label }, key ) in getFilters" :key="key">
                <q-expansion-item :header-inset-level="1" expand-separator class="filter-child" :label="label"
                    default-closed>
    
                    <div v-if="key !== 'price_range'">
                        <div class="column" v-if="Array.isArray(data)">
                            <q-input outlined 
                                :placeholder="`Busque por ${label}`" 
                                 v-model="searchQueriesArray[key]"
                            >
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
    
                            <q-checkbox 
                                v-for="item in getVisibleItemsArray(key)" 
                                :key="item.id"
                                :name="item.name || item.title" 
                                :val="item.id"
                                v-model="modelIndividualFilters[key]" 
                                size="sm" 
                                :label="item.name || item.title" 
                            />
    
                            <q-btn 
                                flat 
                                color="primary" 
                                v-if="filteredItemsArray[key].length > 7"
                                @click="toggleShowAll(key)"
                                class="btn-see-more"
                            >
                                {{ showAll[key] ? 'Ver menos' : 'Ver mais' }}
                            </q-btn>
                        </div>
                        
                        <div v-else-if="typeof data == 'object'" class="column">
                            <q-input outlined 
                                :placeholder="`Busque por ${label}`" 
                                v-model="searchQueriesObject[key]"
                            >
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
    
                            <q-checkbox 
                                v-for="(itemValue, itemKey) in getVisibleItemsObject(key)" 
                                :key="itemKey"
                                :name="itemValue" 
                                :val="itemKey"
                                v-model="modelFilterKeys[key]" 
                                size="sm" 
                                :label="itemValue" 
                            />
                        </div>
                    </div>  
    
                    <div v-else class="column">
                        <q-range 
                            name="price_range" 
                            @change="changedPriceRange" 
                            v-model="modelRangePrice" 
                            :min="minPrice" :max="maxPrice"
                            label-always 
                            :right-label-value="'R$ ' + modelRangePrice.max"
                            :left-label-value="'R$ ' + modelRangePrice.min" 
                        />
                    </div>
    
                </q-expansion-item>
            </div>
        </div>

        <q-btn @click="buttonFiltered" class="button-filtered" color="primary" border>
            <span>Filtrar</span>
        </q-btn>
    </div>
</template>
<script setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { useQuasar } from 'quasar';

const $q = useQuasar();

const {
    fetchFilters, setIndividualFilters, fetchProductsAndFilters, setLoading, setOrderBy,
    setDrawer, setSelectedAllProducts, setFilterKeys, setPriceRange
} = useActions({
    fetchFilters: 'my_lists/fetchFilters',
    setIndividualFilters: 'my_lists/setIndividualFilters',
    fetchProductsAndFilters: 'my_lists/fetchProductsAndFilters',
    setLoading: 'my_lists/setLoading',
    setOrderBy: 'my_lists/setOrderBy',
    setDrawer: 'my_lists/setDrawer',
    setSelectedAllProducts: 'my_lists/setSelectedAllProducts',
    setFilterKeys: 'my_lists/setFilterKeys',
    setPriceRange: 'my_lists/setPriceRange'
})

const { 
    partner, getFilters, getResearchingProduct, getFilterKeys
 } = useGetters({
    partner: "partner/getPartner",
    getFilters: 'my_lists/getFilters',
    getResearchingProduct: 'my_lists/getResearchingProduct',
    getFilterKeys: 'my_lists/getFilterKeys',
})

const modelIndividualFilters = ref({});
const modelFilterKeys = ref({})
const minPrice = ref(null);
const maxPrice = ref(null);
const modelRangePrice = ref({ min: 0, max: 0 });

// Estados para consultas de pesquisa
const searchQueriesArray = ref({});  // Para filtros que são arrays (e.g., brands, categories)
const searchQueriesObject = ref({}); // Para filtros que são objetos (e.g., age, gender)

// Armazena o estado de "Ver mais" ou "Ver menos" para cada filtro
const showAll = ref({});


// Ouve os checkbox para adicionar/remover
// Esse ouve apenas do tipo array ( ex: categories e brands )
watch(modelIndividualFilters, (newValue) => {
     // Pega os filtros que já estão salvos, e junta em um só obj
     const updatedFilterKeys = Object.assign({}, getFilterKeys.value, newValue);

    setIndividualFilters(newValue);
    setFilterKeys(updatedFilterKeys)
}, { deep: true });


// Ouve os checkbox para adicionar/remover
// Esse ouve apenas do tipo object ( ex: gender e age )
watch(modelFilterKeys, (newValue) => {

    // Pega os filtros que já estão salvos, e junta em um só obj
    const updatedFilter = Object.assign({}, getFilterKeys.value, newValue);

    setFilterKeys(updatedFilter)
}, { deep: true })

// Se o usuário pesquisar usando o q-input 'Busque por produtos', remove os checkbox ativos
watch(getResearchingProduct, (newValue) => {
    if (newValue) clearActiveCheckboxes();
})

// Cria um model para cada chave de filtro (como categories, brands, price_range)
watch(() => getFilters.value, (filters) => {
    if (filters)
        for (const key in filters) {

            const { data } = filters[key];

            if (Array.isArray(data)) { // Categories, Brands

                modelIndividualFilters.value[key] = modelIndividualFilters.value[key] || [];
                
            } else if (typeof data === 'object' && key !== 'price_range') { // Age, Gender

                modelFilterKeys.value[key] = modelFilterKeys.value[key] || [];

            } 
            
            if ( key === 'price_range' ) {
                const { data } = filters[key];

                minPrice.value = data.min_price;
                maxPrice.value = data.max_price;

                modelRangePrice.value = {
                    min: minPrice.value,
                    max: maxPrice.value
                };
            }
        }

}, { deep: true });


// Filtra itens que são arrays com base na pesquisa
const filteredItemsArray = computed(() => {
  const result = {};
  for (const key in getFilters.value) {
    const { data } = getFilters.value[key];

    if (Array.isArray(data)) {
      const query = searchQueriesArray.value[key]?.toLowerCase() || '';

      result[key] = data.filter((item) => {

        const label = item.name || item.title || '';
        return label.toLowerCase().includes(query);

      });
    }
  }
  return result;
});

// Filtra itens que são objetos com base na pesquisa
const filteredItemsObject = computed(() => {
  const result = {};

  for (const key in getFilters.value) {
    
    const { data } = getFilters.value[key];

    // Verifica se o dado é um objeto
    if (typeof data === 'object' && !Array.isArray(data) && key !== 'price_range' ) {

      const query = searchQueriesObject.value[key]?.toLowerCase() || '';

      console.log('QueryObject', query)

      result[key] = Object.entries(data).filter(
        item => item[1] && item[1].toLowerCase().includes(query) 
      ).reduce((acc, [itemKey, itemValue]) => {
        acc[itemKey] = itemValue;
        return acc;
      }, {});

    }
  }
  
  return result;
});



// METHODS

// Função para alternar o estado de exibição de itens para um filtro específico
const toggleShowAll = (key) => showAll.value[key] = !showAll.value[key];


// Função para obter itens visíveis filtrados de arrays
const getVisibleItemsArray = (key) => {
  const items = filteredItemsArray.value[key] || [];

  if ( searchQueriesArray.value[key]?.trim() ) return items;

  // Se "Ver mais" estiver ativo, mostra todos os itens filtrados.
  return showAll.value[key] ? items : items.slice(0, 7);
};



// Função para obter itens visíveis filtrados de arrays
const getVisibleItemsObject = (key) => filteredItemsObject.value[key] || [];



// Metodo disparado sempre que há alteração no input price_range
const changedPriceRange = () => {
    const priceRangeObj = {
        min_price: modelRangePrice.value.min,
        max_price: modelRangePrice.value.max
    }

    setPriceRange(priceRangeObj);
};


// Função disparada quando clica em 'filtrar'
const buttonFiltered = async () => {
    // Em telas menores, fecha o menu após clicar em filtrar
    if ($q.screen.lt.md) {
        setDrawer(false)
    }

    setOrderBy({})
    setSelectedAllProducts([])
    setLoading(true)

    try {
        await fetchProductsAndFilters()
    } catch (error) {
        console.error('Erro ao busca produto', error)
    } finally {
        setLoading(false)
    }
}

// Método para desmarcar todos os checkboxes
const clearActiveCheckboxes = () => {
    for (const key in modelIndividualFilters.value) {
        if (Array.isArray(modelIndividualFilters.value[key])) {
            modelIndividualFilters.value[key] = []; // Limpa todos os checkboxes selecionados para este filtro
        }
    }
};



await new Promise(resolve => setTimeout(resolve, 1000));

try {
    const payload = {
        parent_id: [partner.value?.id],
        params: ""
    }

    await fetchFilters(payload)
} catch (error) {
    console.error('Houve um erro ao buscar os filtros', error)
}

onMounted(() => document.body.classList.remove('theme-light-dark'));

onBeforeUnmount(() => document.body.classList.add('theme-light-dark'));

</script>
<style scoped>
.filter {
    position: relative;
    scrollbar-width: none
}

.filters {
    display: flex;
    flex-direction: column;
    gap: 17px;
    padding-bottom: 2.2rem;
}

.column {
    display: flex;
    flex-direction: column;
}

.filter-child {
    border-radius: 8px;
    padding: 5px 15px;
    border: 1px solid rgba(0, 0, 0, 0.24);
}

.filter-child .q-input {
    margin: 2px 0px 14px 0px;
}

.filter-child strong {
    margin-bottom: 5px;
}

.q-range {
    margin-top: 2rem;
    padding: 0 0.7rem;
}


:deep(.q-btn__content) {
    text-transform: initial;
    text-align: left;
}

:deep(.q-checkbox__label) {
    font-size: clamp(12px, 3.5vw, 14px)
}

:deep(.q-checkbox__label) {
    text-transform: capitalize;
}

:deep(.q-slider__text) {
    font-size: 10px
}

:deep(.q-slider__text-container) {
    display: flex;
    height: fit-content;
    align-items: center
}

:deep(.q-expansion-item__container > .q-item) {
    padding: 0px !important
}

:deep(.q-item__label) {
    text-transform: capitalize;
}

:deep(.q-field__native) {
    height: 35px
}

:deep(.q-field__native::placeholder) {
    font-size: clamp(10px, 3.5vw, 13px)
}

:deep(.q-slider__track),
:deep(.q-slider__thumb),
:deep(.q-btn.text-primary),
:deep(.q-checkbox__inner--truthy) {
    color: var(--primary-custom-color) !important;
}

.btn-see-more {
    margin-top: 7px;
}

.button-filtered {
    position: sticky;
    bottom: 0.5rem;
    left: 0;
    width: 100%;
    z-index: 2;
    background: var(--primary-custom-color) !important;
}

@media ( min-width: 768px ) {
    .filter {
        -webkit-overflow-scrolling: touch !important; /* Ativa a rolagem com inércia no Safari */
        height: 100%;
        overflow: auto;
    }

    .button-filtered {
        bottom: 2rem;
    }
}

</style>
<style scoped src="../../styles/q-input.css"></style>