<template>
    <div class="product" :data-category-id="product.category_id">
        <q-linear-progress color="primary" indeterminate v-if="isLoadingAddingToFavorites" />
        <div class="product-content" ref="refProductContent">
            <div class="product-header">
                <a href="#" class="add-favorite" @click="addToFavorite">
                    <q-icon color="primary" :name="iconName" size="1.4rem" />
                </a>
                <q-img :src="product.full_url" loading="lazy" />
            </div>
            <div class="product-body">
                <small v-text="product.category_title" />
                <h4 v-text="product.name" />
                <p 
                    :class="promotionalPriceIsDifferentFromTheOriginal ? 'sale-price-exists' : ''" 
                    class="price_ref"
                    v-text="price(product.price_ref)"
                />
                <p 
                    v-if="promotionalPriceIsDifferentFromTheOriginal"
                    class="sale-price"
                    v-text="price(product.sale_price)"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, defineProps, ref, watch } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { notifyInfo } from "@/shared/helpers/notify";

const props = defineProps({
    product: {
        type: Object,
        required: true
    }
})

const iconName = ref('favorite_border')
const isLoadingAddingToFavorites = ref(false);
const refProductContent = ref(null)

const { getSelectedAllProducts } = useGetters({
    getSelectedAllProducts: 'my_lists/getSelectedAllProducts',
})

const { 
    setProductsAsFavorite, setSelectedAllProducts, fetchProductsAndFilters,
    setLoadingAddingOneProduct
 } = useActions({
    setProductsAsFavorite: 'products/setProductsAsFavorite',
    setSelectedAllProducts: 'my_lists/setSelectedAllProducts',
    fetchProductsAndFilters: 'my_lists/fetchProductsAndFilters',
    setLoadingAddingOneProduct: 'my_lists/setLoadingAddingOneProduct'
})

watch(getSelectedAllProducts, (newValue) => {
    if ( newValue.length ) {
        const theProductHasBeenSelected =  newValue.find(
            productID => productID == props.product.product_id
        )

        if ( theProductHasBeenSelected ) iconName.value = 'favorite';
    } else {
        iconName.value = 'favorite_border';
    }
}, { deep: true })

//METHODS


// Se o preço promocional não existir ou for igual ao preço original retorna undefined
const promotionalPriceIsDifferentFromTheOriginal = computed(() => {
    if ( !props.product.sale_price ) return undefined;

    if ( props.product.sale_price == props.product.price_ref ) return undefined;

    return true 
})


const price = (price) => {
    return `R$ ${Number(price).toFixed(2)}`
}

// Função para adicionar o produto ao array de produtos selecionados
const addProductInAllSelected = () => {
    const updatedSelectedProducts = [...getSelectedAllProducts.value, props.product.product_id];
    setSelectedAllProducts(updatedSelectedProducts);

    iconName.value = 'favorite';
};

// Função para remover o produto do array de produtos selecionados
const removeProductInAllSelected = ( index ) => {

    const updatedSelectedProducts = [...getSelectedAllProducts.value];
    updatedSelectedProducts.splice(index, 1);
    setSelectedAllProducts(updatedSelectedProducts);

    iconName.value = 'favorite_border';
};

// Função para verificar se a seleção múltipla está habilitada
const multipleSelectionIsEnabled = () => {
    if (getSelectedAllProducts.value.length === 0) return false;

    const productIndex = getSelectedAllProducts.value.findIndex(
        productID => productID == props.product.product_id
    );

    // Alterna entre adicionar ou remover o produto da lista de selecionados
    productIndex === -1 ? addProductInAllSelected() : removeProductInAllSelected(productIndex);

    return true;
};

const addToFavorite = async () => {
    if ( multipleSelectionIsEnabled() ) return;

    setLoadingAddingOneProduct(true)

    iconName.value = 'favorite';

    isLoadingAddingToFavorites.value = true

    refProductContent.value.classList.add('flipnet-opacity')

    try {
        await setProductsAsFavorite({ prod_ids: [ props.product.product_id ] });

        notifyInfo("products.product_marked_as_favorite")
        
        await fetchProductsAndFilters()

    } catch (error) {
        console.error('Houve um erro ao salvar favorito', error)
    } finally {
        setLoadingAddingOneProduct(false)
        isLoadingAddingToFavorites.value = false
        iconName.value = 'favorite_border'
        refProductContent.value.classList.remove('flipnet-opacity')
    }
}
</script>
<style scoped>
.product {
    border-radius: 6px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.product-header {
    background-color: #F5F6F8;
    position: relative;
}

.product-body {
    padding: 0px 0.55rem 0.2rem 0.55rem
}

.add-favorite {
    position: absolute;
    right: 0.35rem;
    top: 0.3rem;
    z-index: 5;
    transition: transform 0.3s ease;
}

.add-favorite:hover {
    transform: scale(1.1)
}

.q-img {
    width: 120px;
    height: 150px;
    margin: 0px auto 8px auto;
    display: flex;
}

small {
    border: 1px solid #e2e8f0;
    border-radius: 40px;
    padding: 0.2rem 0.5rem;
    font-size: clamp(9px, 3.5vw, 10px);
}

h4 {
    font-size: clamp(12px, 3.5vw, 14px);
    font-weight: 400;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    height: calc(3 * 1.35em);
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 2rem;
}

p {
    font-weight: 500;
}

p {
    font-size: clamp(14px, 3.5vw, 14.5px);
}

p.sale-price {
    margin-top: 0.3rem;
}

.sale-price-exists {
    font-size: clamp(11px, 3.1vw, 13px) !important;
    text-decoration: line-through;
    color: #999;
    margin-top: 0rem;
    margin-bottom: 0rem
}

.q-btn {
    background-color: #2980b9;
    color: #fff;
    width: 100%;
    border-radius: 8px;
}

.q-btn span {
    text-transform: capitalize;
}

:deep(.q-icon.text-primary) {
    color: var(--primary-custom-color) !important;
}
</style>