<template>
    <section class="section-products" @scroll="handleScroll">
        <div class="section-inner">
            <div class="toolbar">
                <q-btn 
                    v-if="$q.screen.lt.md"
                    class="btn-filters q-pa-none"
                    @click="setDrawer(!getDrawer)"
                    flat
                >
                    <q-icon name="filter_list" size="0.95rem" />
                    <span>Filtrar</span>
                </q-btn>

                <q-btn-dropdown
                    v-if="getSelectedAllProducts.length"
                    flat no-caps
                    class="btn-select-all-products"
                    :label="getSelectedAllProducts.length + ' Produtos'"
                >
                    <q-list>
                        <q-item clickable v-close-popup @click="saveMultipleSelected">
                            <q-item-section avatar>
                                <q-icon name="favorite" size="1.2rem" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Salvar favoritos selecionados</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup @click="cancelMultipleSelected">
                            <q-item-section avatar>
                                <q-icon name="close" size="1.2rem" />
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Desmarcar favoritos</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>

               <div 
                class="toolbar-inner" 
                :class="getLoadingAddingOneProduct?.status ? 'flipnet-opacity': ''"
                v-if="products.length"
               >   
                    <OrderBy />   
                    <q-checkbox 
                        v-model="modelMultipleSelected" 
                        size="xs" val="xs" :label="labelMultipleSelected" 
                        @update:model-value="setSelectedAllProducts"
                    />
               </div>
            </div>
        </div>

        <div class="products-container">
            <div v-if="products.length">
                <div class="products">
                    <Product v-for="( product, index ) in products" :key="index" :product="product" />
                </div>
                <pagination
                    class="pagination"
                    :resource="getPagination"
                    :repository="handlePageChange"
                />
            </div>
            <NoProducts v-else />
        </div>
    </section>
</template>
<script setup>
import { ref, watch, defineProps } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';
import { notifyInfo } from "@/shared/helpers/notify";
import { useQuasar } from 'quasar';

import Product from './product.vue';
import OrderBy from './order-by.vue';
import Pagination from "@/modules/main/components/pagination";
import NoProducts from './no-products.vue';
// import Header from './header.vue';

const props = defineProps({
    scrollToTop: {
        type: Function,
        required: true
    },
    activeScrollBoxShadowHeader: {
        type: Function
    }
})

const $q = useQuasar();

const products = ref([]);
const modelMultipleSelected = ref(false)
const labelMultipleSelected = ref('Selecionar todos');


const { 
    partner, getProducts, getPagination, influencer, getCurrentPage,
    getSelectedAllProducts, getDrawer, getLoadingAddingOneProduct
 } = useGetters({
    partner: 'partner/getPartner',
    getProducts: 'my_lists/getProducts',
    getPagination: 'my_lists/getPagination',
    influencer: 'account/getPartnerAccount',
    getCurrentPage: 'my_lists/getCurrentPage',
    getSelectedAllProducts: 'my_lists/getSelectedAllProducts',
    getDrawer: 'my_lists/getDrawer',
    getLoadingAddingOneProduct: 'my_lists/getLoadingAddingOneProduct'
})

const { 
    fetchProducts, setParentsIDs, setLoading, setCurrentPage, fetchProductsAndFilters,
    setInfluencerID, setSelectedAllProducts, setProductsAsFavorite, setDrawer,
} = useActions({
    fetchProducts: 'my_lists/fetchProducts',
    setParentsIDs: 'my_lists/setParentsIDs',
    setLoading: 'my_lists/setLoading',
    setCurrentPage: 'my_lists/setCurrentPage',
    fetchProductsAndFilters: 'my_lists/fetchProductsAndFilters',
    setInfluencerID: 'my_lists/setInfluencerID',
    setSelectedAllProducts: 'my_lists/setSelectedAllProducts',
    setProductsAsFavorite: 'products/setProductsAsFavorite',
    setDrawer: 'my_lists/setDrawer'
})

watch(modelMultipleSelected, (newValue) => {
    if ( newValue ) {
        const { data } = getProducts.value

        const getOnlyProductsID = data.map(product => product?.product_id)

        setSelectedAllProducts(getOnlyProductsID)

         labelMultipleSelected.value = 'Desmarcar'
    } else {
        setSelectedAllProducts([])
        labelMultipleSelected.value = 'Selecionar todos'
    }
})

watch(getSelectedAllProducts, (newValue) => {
    if ( newValue.length ) modelMultipleSelected.value = true;
    else modelMultipleSelected.value = false
})


watch(() => getProducts.value, (newValue) => {
    if (newValue && newValue.data) {
        products.value = newValue.data;
    }
}, { deep: true })

const handlePageChange = async ( currentPage ) => {
    setSelectedAllProducts([])
    setCurrentPage(currentPage.page)
    setLoading(true)

    try {
        await fetchProductsAndFilters()
        
        props.scrollToTop()
    } catch (error) {
        console.error('Erro ao busca produto', error)
    } finally {
        setLoading(false)
    }
}

const handleScroll = (event) => props.activeScrollBoxShadowHeader(event);

const cancelMultipleSelected = () => {
    modelMultipleSelected.value = false
    setSelectedAllProducts([])
}

const saveMultipleSelected = async () => {
    const getMultipleSelectedProducts = getSelectedAllProducts.value;

    setLoading(true)
    cancelMultipleSelected()

    try {
        await setProductsAsFavorite({ prod_ids: [...getMultipleSelectedProducts] })

        await fetchProductsAndFilters()

        notifyInfo(`${getMultipleSelectedProducts.length} produtos foram adicionados aos favoritos`)
    } catch (error) {
        console.log('Houve um erro ao salvar múltiplos favoritos', error)
    } finally {
        setLoading(false) 
    }
}

await new Promise(resolve => setTimeout(resolve, 1000));

try {
    setParentsIDs([ partner.value?.id ])
    setInfluencerID([ influencer.value?.id ])

    const payloadFetchProducts = {
        parent_ids: [partner.value?.id],
        favorites: 0,
        partner_ids: [influencer.value?.id],
        page: getCurrentPage.value
    }

    await fetchProducts(payloadFetchProducts)
} catch (error) {
    console.log('Houve um error', error)
}
</script>

<style scoped lang="scss">
.section-products {
    height: 100vh;
    overflow: auto;
    scrollbar-width: none;
}

.mobile {
    .section-products {
        padding-bottom: 200px;
        -webkit-overflow-scrolling: touch !important; /* Ativa a rolagem com inércia no Safari */
    }
}

.products-container {
    margin-top: 20px;
    padding-bottom: 20px;
}

.section-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 0px;
}

.toolbar {
   display: flex;
   gap: 15px;
   justify-content: space-between;
   width: 100%;
}

.toolbar .q-btn:first-child {
    align-self: baseline
}

.toolbar-inner {
    display: grid;
    align-items: center;
    gap: 10px;
}

.toolbar-inner > div :deep(button){
    float: right;
}

.toolbar-inner :deep(.q-checkbox__inner) {
    margin-left: auto;
}

:deep(.btn-filters .q-btn__content) {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 3px;
}

:deep(.btn-filters .q-btn__content span) {
    text-transform: initial;
    font-weight: 400;
}

:deep(.btn-filters .q-btn__content i) {
    top: -0.1rem;
}

:deep(.q-checkbox__label) {
    font-size: clamp(13px, 2.5vw, 14px);
    position: relative;
    top: 0.05rem;
}

:deep(.q-item__section--avatar) {
    min-width: fit-content;
    padding-right: 0.5rem;
}

:deep(.q-item) {
    min-height: 0px;
    margin: 0.5rem 0.2rem;
}

.btn-select-all-products {
    border: 1px solid rgba(0, 0, 0, 0.24);
    border-radius: 100px;
    max-width: 145px;
    display: flex;
    height: fit-content;
    min-height: fit-content;
    gap: 7px;
    align-items: center;
    justify-content: center;
    text-transform: initial;
    width: 100%;
    font-weight: normal;
    padding: 4px 16px;
}

.btn-select-all-products :deep(.q-btn__content) {
    font-size: clamp(12.5px, 2.5vw, 14px);
}

.q-list .q-item:first-child :deep(.q-icon) {
    color: var(--primary-custom-color) !important;
    fill: var(--primary-custom-color) !important;
}


.q-list .q-item:last-child :deep(.q-icon) {
    color: rgba(214, 48, 49,1.0) !important;
}

.btn-select-all-products span {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 76%;
    white-space: nowrap;
}

.select-all-products > div {
    justify-content: center;
    display: flex;
}

.select-all-products .q-btn :deep(.q-btn__content) {
    font-size: clamp(13px, 2.5vw, 14px);
}

.select-all-products span {
    text-align: center;
}

.select-all-products .q-btn {
    text-transform: initial;
    font-weight: 400;
    border-radius: 100px;
}

.select-all-products .q-btn :deep(.q-btn__content) {
    font-size: clamp(11px, 2.5vw, 12.5px);
}

.select-all-products .q-btn:first-child {
    background-color: var(--primary-custom-color);
    color: #fff;
}

:deep(.q-checkbox__inner--truthy) {
    color: var(--primary-custom-color);
}


h6 {
    margin: 0px;
    font-size: clamp(14px, 3.5vw, 16px);
    text-align: left;
}

.products {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    justify-content: start;
}

.pagination :deep(.q-btn.bg-black){
    background: var(--primary-custom-color) !important;
}

@media ( min-width: 640px ) {
    .section-products {
        height: calc(var(--my-lists_q-card-height) - 100px);
        padding-right: 10px;
    }

    .products {
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
    }

    .toolbar-inner {
        display: flex;
        margin-left: auto
    }

    .toolbar-inner > div:first-child {
        order: 2
    }

    .toolbar-inner > div:last-child {
        order: 1
    }

    .select-all-products {
        padding: 0.7rem 4rem;
        display: flex;
        width: fit-content;
        margin: 0.5rem auto;
    }

    .btn-select-all-products span {
        max-width: 100%;
    }
}
</style>