<template>
    <div class="header">
        <q-linear-progress color="primary" indeterminate v-if="getLoading" />
        <div class="header-content">
            <div class="header-inner">
                <div class="left">
                    <q-img :src="logo" width="80px"/>
                </div>
                <div class="center" v-if="$q.screen.gt.sm">
                    <h4>Adicione produtos à sua coleção Favoritos</h4>
                </div>
                <div class="right">
                    <button @click="dialogClose"> 
                        <q-icon name="close" size="1.6rem" />
                    </button>
                </div>
            </div>
    
            <div class="searchbar">
                <q-form 
                    @submit="submitSearch"
                >
                    <q-input
                        outlined 
                         v-model="searchText" 
                        :dense="dense" 
                        placeholder="Busque por produtos"
                    >
                    <q-btn type="submit" flat>
                        <q-icon name="search" />
                    </q-btn>
                    </q-input>
                </q-form>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useQuasar } from 'quasar';
import { ref, defineProps } from 'vue';
import { useActions, useGetters } from 'vuex-composition-helpers';

defineProps({
    dialogClose: Function
})

const $q = useQuasar();

const searchText = ref('');

const { logo, getLoading } = useGetters({
    // partner: "partner/getPartner",
    getLoading: 'my_lists/getLoading',
    logo: 'partner/getPartnerParentLogo',
})

const { 
    fetchProductsAndFilters, setTextSearch, setIndividualFilters,
    setFilterLikes, setOrderBy, setLoading, setSelectedAllProducts,
    setFilterKeys, setResearchingProduct, setPriceRange
} = useActions({
    fetchProductsAndFilters: 'my_lists/fetchProductsAndFilters',
    setTextSearch: 'my_lists/setTextSearch',
    setIndividualFilters: 'my_lists/setIndividualFilters',
    setFilterLikes: 'my_lists/setFilterLikes',
    setOrderBy: 'my_lists/setOrderBy',
    setLoading: 'my_lists/setLoading',
    setSelectedAllProducts: 'my_lists/setSelectedAllProducts',
    resetFiltersPayloads: 'my_lists/resetFiltersPayloads',
    setFilterKeys: 'my_lists/setFilterKeys',
    setResearchingProduct: 'my_lists/setResearchingProduct',
    setPriceRange: 'my_lists/setPriceRange'
})

//METHODS
const submitSearch = async () => {
    setLoading(true)
    setResearchingProduct(true)

    const filterLikes = searchText.value.length > 0 ? { 
        name: searchText.value,
        description: searchText.value,
        short_description: searchText.value
    } : {}

    setFilterLikes(filterLikes)
    setTextSearch(searchText.value)

    setIndividualFilters({})
    setOrderBy({})
    setSelectedAllProducts([])
    setFilterKeys({})
    setPriceRange({})
    
    try {
        await fetchProductsAndFilters()
    } catch (error) {
        console.error('Erro ao busca produto', error)
    } finally {
        setLoading(false)
        setResearchingProduct(false)
    }
}

</script>
<style scoped>
    .header {
        height: 100px;
    }

    .header-content {
        padding: 0.5rem 16px
    }

    .header-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .searchbar {
        margin-top: 10px;
    }

    h4 {
        margin: 0px;
        font-size: clamp(12px, 4vw, 16px);
        font-weight: 500;
        line-height: 1.3
    }

    .q-field {
        width: 100%
    }

    :deep(i), 
    :deep(.q-icon.text-primary)
    {
        color: var(--primary-custom-color) !important;
    }

    button {
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
    }
    
</style>
<style scoped src="../../styles/q-input.css" ></style>