<template>
    <div class="q-gutter-y-md container">
        <q-card class="q-pa-lg">
            <Header />
        </q-card>
        <q-card>
            <q-tabs
              v-model="tab"
              dense
              class="text-grey"
              active-color="primary"
              indicator-color="primary"
              align="left"
              narrow-indicator
            >
              <q-tab name="favorites" label="Favoritos" class="q-pt-md" />
            </q-tabs>
    
            <q-separator />
    
            <q-tab-panels v-model="tab" animated>
              <q-tab-panel name="favorites">

                <Suspense>
                    <template #default>
                        <ActionButtons />
                    </template>
                    <template #fallback>
                        <SkeletonActionButtons />
                    </template>
                </Suspense>

                <FavoritesProducts :my_lists_component="true" class="favorites" />
              </q-tab-panel>
            </q-tab-panels>
          </q-card>
    </div>
</template>
<script setup>
import { ref } from "vue";

//Components Vue
import FavoritesProducts from '../products/components/FavoritesProductsContainer.vue';
import Header from './header.vue';
import ActionButtons from "./actionButtons.vue";
import SkeletonActionButtons from "./skeletons/skeletonActionButtons.vue";
//Components Vue

const tab = ref('favorites');

</script>
<style scoped>
    .container {
        display: flex;
        flex-direction: column;
    }

    .container > .q-btn {
        align-self: end;
        background-color: #1F2A37 !important;
        color: hsl(0, 0%, 100%);
        text-transform: initial;
    }
    
    .q-card {
        border-radius: 8px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
    }

    :deep(.text-info) {
        display: none
    }

    :deep(.all-products) {
        padding-top: 0px
    }

    :deep(.text-primary) {
        color: var(--primary-custom-color) !important
    }
</style>